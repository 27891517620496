var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.companyProfile.facebook),callback:function ($$v) {_vm.$set(_vm.companyProfile, "facebook", $$v)},expression:"companyProfile.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Twitter'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.companyProfile.twitter),callback:function ($$v) {_vm.$set(_vm.companyProfile, "twitter", $$v)},expression:"companyProfile.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.companyProfile.linkedIn),callback:function ($$v) {_vm.$set(_vm.companyProfile, "linkedIn", $$v)},expression:"companyProfile.linkedIn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CompanyForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.companyProfile.otherLinks),callback:function ($$v) {_vm.$set(_vm.companyProfile, "otherLinks", $$v)},expression:"companyProfile.otherLinks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.SaveChanges"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }