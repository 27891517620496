<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ $t("AdvisorForm.Advisor_Details") }}</h5>
              <small class="text-muted">
                {{ $t("AdvisorForm.Ente_Advisor_Details") }}
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('AdvisorForm.Full_Name')"
                label-for="Fomraml_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="Fomraml_name"
                    v-model="advisorProfile.advisor.fullName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('AdvisorForm.Full_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('AdvisorForm.Email')" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="advisorProfile.advisor.emailAddress"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john.doe@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('AdvisorForm.Phone_Number')"
                label-for="Phone"
              >
                <validation-provider name="phone" rules="required">
                  <VuePhoneNumberInput
                    v-model="advisorProfile.advisor.advisorPhone.phoneNumber"
                    color="#ea5455"
                    error-color="#ea5455"
                    @update="onUpdate"
                    :default-country-code="
                      advisorProfile.advisor.advisorPhone.code
                    "
                    :translations="
                      $store.state.locale.locale == 'ar'
                        ? translationsArabic
                        : translations
                    "
                  />

                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <small v-if="!isValidNumber" class="text-danger">{{
                    $t("common.phone_Invalid")
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="owner_type"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" label-for="owner_type"
                    >{{ $t("AdvisorForm.Owner_Type") }}
                  </label>

                  <v-select
                    id="owner_type"
                    v-model="advisorProfile.advisor.ownerType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? OwnerTypeOptionArabic
                        : OwnerTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              v-if="advisorProfile.advisor.ownerType.value == 'individual'"
            >
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  :label="$t('AdvisorForm.Gender')"
                  label-for="Gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Gender"
                    v-model="advisorProfile.advisor.gender"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? GenderOptionArabic
                        : GenderOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    advisorProfile: {},
  },
  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: "",
      code: "",
      phoneCode: "",
      formatAge: [],
      GenderOption: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
      ],
      GenderOptionArabic: [
        { value: "male", text: "ذكر" },
        { value: "female", text: "أنثى" },
      ],
      OwnerTypeOption: [
        { value: "individual", text: "Individual" },
        { value: "group", text: "Group" },
      ],
      OwnerTypeOptionArabic: [
        { value: "individual", text: "فرد" },
        { value: "group", text: "مجموعة" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      editAdvisor: "profile/editAdvisor",
    }),
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            this.show = true;
            const advisorData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            advisorData.append("userId", this.user._id);
            advisorData.append("userType", "advisor");
            advisorData.append(
              "fullName",
              this.advisorProfile.advisor.fullName
            );
            advisorData.append(
              "emailAddress",
              this.advisorProfile.advisor.emailAddress
            );
            advisorData.append(
              "phoneNumber",
              this.advisorProfile.advisor.advisorPhone.phoneNumber.replace(
                /\s/g,
                ""
              )
            );
            advisorData.append("phoneCode", this.PhoneCode);
            advisorData.append(
              "ownerType",
              this.advisorProfile.advisor.ownerType.value
            );
            if (this.advisorProfile.advisor.ownerType.value == "individual") {
              advisorData.append(
                "gender",
                this.advisorProfile.advisor.gender.value
              );
            }

            this.editAdvisor(advisorData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                this.errorMessage = error.response.data.error.message;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onUpdate(payload) {
      this.isValidNumber = payload.isValid;
      this.PhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },
  },
  mounted() {
    this.configValidate();
  },
};
</script>
