<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      spinner-small
      spinner-variant="primary"
    >
      <template #overlay>
        <div class="text-center">
          <b-spinner v-show="show" type="grow" label="Loading..." />
          <h4 id="cancel-label">
            {{ $t("loader") }}
          </h4>
        </div>
      </template>
      <div>
        <h2 class="mb-2">{{ $t("dashboard.AccountSettings") }}</h2>
        <!-- For Company tab -->
        <b-tabs
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
          v-if="user.userType == 'company'"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <company-details :companyProfile="companyProfileData" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <company-info :companyProfile="companyProfileData" />
          </b-tab>

          <!-- address tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="MapIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Address")
              }}</span>
            </template>

            <company-address :companyProfile="companyProfileData" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <company-social
              :companyProfile="companyProfileData.company.socialMedia"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <company-files :companyProfile="companyProfileData" />
          </b-tab>
          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->

          <!-- notification -->
        </b-tabs>

        <!-- For Investor tab -->
        <b-tabs
          v-if="user.userType == 'investor'"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <investor-details :investor-profile="investorProfileData0" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <investor-info :investor-profile="investorProfileData0" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <investor-social
              :investor-profile="investorProfileData0.investor.socialMedia"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <investor-files :investor-profile="investorProfileData0" />
          </b-tab>

          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>

        <!-- For Freelancer tab -->
        <b-tabs
          v-if="user.userType == 'freelancer'"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <freelancer-details :freelancer-profile="freelancerProfileData" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <freelancer-info :freelancer-profile="freelancerProfileData" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <freelancer-social
              :freelancer-profile="freelancerProfileData.freelancer.socialMedia"
            />
          </b-tab>

          <!-- Educations  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="GlobeIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Educations")
              }}</span>
            </template>

            <freelancer-educations
              :freelancer-profile="freelancerProfileData.freelancer.educations"
            />
          </b-tab>
          <!-- Languages  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="HashIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.languages")
              }}</span>
            </template>

            <freelancer-Languages
              :freelancer-profile="freelancerProfileData.freelancer.languages"
            />
          </b-tab>
          <!-- certifications  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ClipboardIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Certifications")
              }}</span>
            </template>

            <freelancer-Certifications
              :freelancer-profile="
                freelancerProfileData.freelancer.certifications
              "
            />
          </b-tab>
          <!-- experiences  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Experiences")
              }}</span>
            </template>

            <freelancer-experiences
              :freelancer-profile="freelancerProfileData.freelancer.experiences"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <freelancer-files
              :freelancer-profile="freelancerProfileData.freelancer"
            />
          </b-tab>

          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
        </b-tabs>

        <!-- For Broker tab -->
        <b-tabs
          v-if="user.userType == 'broker'"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <broker-details :broker-profile="brokerProfileData" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <broker-info :broker-profile="brokerProfileData" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <broker-social
              :broker-profile="brokerProfileData.broker.socialMedia"
            />
          </b-tab>
          <!-- Languages  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="HashIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.languages")
              }}</span>
            </template>

            <broker-Languages
              :broker-profile="brokerProfileData.broker.languages"
            />
          </b-tab>

          <!-- experiences  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Experiences")
              }}</span>
            </template>

            <broker-experiences
              :broker-profile="brokerProfileData.broker.experiences"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <broker-files :broker-profile="brokerProfileData.broker" />
          </b-tab>
          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>

        <!-- For Advisor tab -->
        <b-tabs
          v-if="user.userType == 'advisor'"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <advisor-details :advisor-profile="advisorProfileData" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <advisor-info :advisor-profile="advisorProfileData" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <advisor-social
              :advisor-profile="advisorProfileData.advisor.socialMedia"
            />
          </b-tab>

          <!-- Languages  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="HashIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.languages")
              }}</span>
            </template>

            <advisor-Languages
              :advisor-profile="advisorProfileData.advisor.languages"
            />
          </b-tab>

          <!-- experiences  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Experiences")
              }}</span>
            </template>

            <advisor-experiences
              :advisor-profile="advisorProfileData.advisor.experiences"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <advisor-files :advisor-profile="advisorProfileData.advisor" />
          </b-tab>

          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>

        <!-- For Partner tab -->
        <b-tabs
          v-if="user.userType == 'partner'"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.General")
              }}</span>
            </template>

            <partner-details :partner-profile="partnerProfileData" />
          </b-tab>
          <!--/ general tab -->

          <!-- info -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="InfoIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Information")
              }}</span>
            </template>

            <partner-info :partner-profile="partnerProfileData" />
          </b-tab>

          <!-- social links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="LinkIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Social") }}</span>
            </template>

            <partner-social
              :partner-profile="partnerProfileData.partner.socialMedia"
            />
          </b-tab>

          <!-- Languages  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="HashIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.languages")
              }}</span>
            </template>

            <partner-Languages
              :partner-profile="partnerProfileData.partner.languages"
            />
          </b-tab>

          <!-- experiences  -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.Experiences")
              }}</span>
            </template>

            <partner-experiences
              :partner-profile="partnerProfileData.partner.experiences"
            />
          </b-tab>

          <!-- files links -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="FileIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{ $t("dashboard.Files") }}</span>
            </template>

            <partner-files :partner-profile="partnerProfileData.partner" />
          </b-tab>

          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>

        <!-- For other user tab -->
        <b-tabs
          v-if="!user.userType"
          vertical
          content-class="col-12 col-md-9 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- change password tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">{{
                $t("dashboard.ChangePassword")
              }}</span>
            </template>

            <account-setting-password />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";

import { mapActions, mapGetters } from "vuex";
import { BTabs, BTab, BSpinner, BOverlay } from "bootstrap-vue";

/*---------------- For Company ----------------*/
import CompanyDetails from "./company-setting/Details.vue";
import CompanyInfo from "./company-setting/Info.vue";
import CompanyAddress from "./company-setting/Address.vue";
import CompanySocial from "./company-setting/Social.vue";
import CompanyFiles from "./company-setting/Files.vue";
/*----------------------------------- */

/*---------------- For Investor ----------------*/
import InvestorDetails from "./investor-setting/Details.vue";
import InvestorInfo from "./investor-setting/Info.vue";
import InvestorSocial from "./investor-setting/Social.vue";
import InvestorFiles from "./investor-setting/Files.vue";
/*----------------------------------- */

/*---------------- For Freelancer ----------------*/
import FreelancerDetails from "./freelancer-setting/Details.vue";
import FreelancerInfo from "./freelancer-setting/Info.vue";
import FreelancerSocial from "./freelancer-setting/Social.vue";
import FreelancerLanguages from "./freelancer-setting/Languages.vue";
import FreelancerEducations from "./freelancer-setting/Educations.vue";
import freelancerCertifications from "./freelancer-setting/Certifications.vue";
import freelancerExperiences from "./freelancer-setting/Experiences.vue";
import FreelancerFiles from "./freelancer-setting/Files.vue";
/*----------------------------------- */

/*---------------- For Broker ----------------*/
import brokerDetails from "./broker-setting/Details.vue";
import brokerInfo from "./broker-setting/Info.vue";
import brokerSocial from "./broker-setting/Social.vue";
import brokerLanguages from "./broker-setting/Languages.vue";
import brokerExperiences from "./broker-setting/Experiences.vue";
import brokerFiles from "./broker-setting/Files.vue";
/*----------------------------------- */

/*---------------- For Advisor ----------------*/
import advisorDetails from "./advisor-setting/Details.vue";
import advisorInfo from "./advisor-setting/Info.vue";
import advisorSocial from "./advisor-setting/Social.vue";
import advisorLanguages from "./advisor-setting/Languages.vue";
import advisorExperiences from "./advisor-setting/Experiences.vue";
import advisorFiles from "./advisor-setting/Files.vue";
/*----------------------------------- */
/*---------------- For Partner ----------------*/
import partnerDetails from "./partner-setting/Details.vue";
import partnerInfo from "./partner-setting/Info.vue";
import partnerSocial from "./partner-setting/Social.vue";
import partnerLanguages from "./partner-setting/Languages.vue";
import partnerExperiences from "./partner-setting/Experiences.vue";
import partnerFiles from "./partner-setting/Files.vue";

/*----------------------------------- */

import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";

import store from "@/store";

export default {
  components: {
    BTabs,
    BTab,
    CompanyDetails,
    CompanyInfo,
    CompanyAddress,
    CompanySocial,
    CompanyFiles,
    InvestorDetails,
    InvestorInfo,
    InvestorSocial,
    InvestorFiles,
    FreelancerDetails,
    FreelancerInfo,
    FreelancerSocial,
    FreelancerLanguages,
    FreelancerEducations,
    freelancerCertifications,
    freelancerExperiences,
    FreelancerFiles,
    brokerDetails,
    brokerInfo,
    brokerSocial,
    brokerLanguages,
    brokerExperiences,
    brokerFiles,
    advisorDetails,
    advisorInfo,
    advisorSocial,
    advisorLanguages,
    advisorExperiences,
    advisorFiles,
    partnerDetails,
    partnerInfo,
    partnerSocial,
    partnerLanguages,
    partnerExperiences,
    partnerFiles,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      show: false,
      options: {},

      companyProfileData: {
        company: {
          companyPhone: {
            code: "",
            number: "",
          },
          address: {
            city: "",
            state: "",
            country: {
              _id: "",
              name: " ",
              id: "",
              value: "",
              text: "",
            },
          },
          socialMedia: {
            facebook: "",
            linkedIn: "",
            twitter: "",
            other: "",
          },
          name: "",
          legalName: "",
          companyEmail: "",
          highlights: "",
          description: "",
          companyEmail: "",
          establishDate: null,
          avgMonthlySales: null,
          annualSales: null,
          ebitda: null,
          assets: "",
          industries: [],
          saleType: "",
          licenseNumber: "",
          companyType: null,
          companyCategory: {
            _id: "",
            name: "",
            id: "",
            value: "",
            text: "",
          },
          price: "",
          currency: {
            _id: "",
            name: "",
            id: "",
            value: "",
            text: "",
          },
          files: [],
        },
      },
      investorProfileData0: {
        investor: {
          legalName: "",
          shortName: "",
          emailAddress: "",
          phone: {
            code: "",
            phoneNumber: "",
          },
          designation: "",
          companyName: "",
          highlights: "",
          description: "",
          businessFactors: "",
          companyDescription: "",
          investmentSize: {
            min: "",
            max: "",
          },
          interstedLocations: [],
          address: [],
          industries: [],
          investorRole: "",
          investorCategory: "",
          socialMedia: {
            investorFacebook: "",
            investorLinkedIn: "",
            investorTwitter: "",
            investorOtherLinks: "",
          },
        },
      },
      investorProfileData1: {},
      freelancerProfileData: {
        freelancer: {
          freelancerPhone: {
            code: "",
            phoneNumber: "",
          },
          socialMedia: {
            freelancerFacebook: "",
            freelancerLinkedIn: "",
            freelancerTwitter: "",
            freelancerOthers: "",
          },
          fullName: " ",
          legalName: "",
          passport: null,
          emailAddress: "",
          gender: "",
          nationality: "",
          highlights: "",
          description: " ",
          languages: [],
          educations: [],
          certifications: [],
          skills: [],
          experiences: [],
          locations: [],
          industries: [],
          createdAt: "",
          files: [],
        },
      },
      brokerProfileData: {
        broker: {
          brokerPhone: {
            phoneCode: "",
            phoneNumber: "",
          },
          socialMedia: {
            brokerFacebook: "",
            brokerLinkedIn: "",
            brokerTwitter: "",
            brokerOtherLinks: "",
          },
          fullName: "",
          emailAddress: "",
          highlights: "",
          description: "",
          gender: "",
          ownerType: "",
          nationality: {
            value: "",
            text: "",
          },
          address: [
            {
              value: "",
              text: "",
            },
          ],
          industries: [],
          experiences: [],
          languages: [],
          licenseDetails: "",
          previousWork: "",
        },
      },
      advisorProfileData: {
        advisor: {
          advisorPhone: {
            code: "",
            phoneNumber: "",
          },
          socialMedia: {
            advisorFacebook: "",
            advisorLinkedIn: "",
            advisorTwitter: "",
            advisorOtherLinks: "",
          },
          createdAt: "",
          fullName: "",
          emailAddress: "",
          gender: "",
          ownerType: "",
          highlights: "",
          description: "",
          designation: "",
          address: [],
          industries: [],
          experiences: [],
          languages: [],
          skills: [],
          previousProject: "",
          files: [],
        },
      },
      partnerProfileData: {
        partner: {
          partnerPhone: {
            code: "",
            phoneNumber: "",
          },
          socialMedia: {
            partnerFacebook: "",
            partnerLinkedIn: "",
            partnerTwitter: "",
            partnerOtherLinks: "",
          },
          files: [],
          createdAt: "",
          fullName: "",
          emailAddress: "",
          highlights: "",
          description: "",
          partnerType: "",
          gender: "",
          ownerType: "",
          nationality: {},
          address: [],
          industries: [],
          experiences: [],
          languages: [],
          licenseDetails: "",
          previousWork: "",
        },
      },
    };
  },
  methods: {
    companyFormated() {
      if (this.companyProfileData.company.saleType) {
        if (this.companyProfileData.company.saleType === "1") {
          this.companyProfileData.company.saleType = {
            value: this.companyProfileData.company.saleType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "سعري الخاص"
                : "My Own Price",
          };
        } else if (this.companyProfileData.company.saleType === "2") {
          this.companyProfileData.company.saleType = {
            value: this.companyProfileData.company.saleType,
            text: this.$store.state.locale.locale == "ar" ? "مزاد" : "Auction",
          };
        } else {
          this.companyProfileData.company.saleType = {
            value: "",
            text: "",
          };
        }
      }
      if (this.companyProfileData.company.currency) {
        this.companyProfileData.company.currency = {
          value: this.companyProfileData.company.currency.value,
          text: this.companyProfileData.company.currency.text,
        };
      }
      if (this.companyProfileData.company.companyCategory) {
        this.companyProfileData.company.companyCategory = {
          value: this.companyProfileData.company.companyCategory.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.companyProfileData.company.companyCategory.arabicName
              : this.companyProfileData.company.companyCategory.text,
        };
      }
      if (this.companyProfileData.company.companyType) {
        if (this.companyProfileData.company.companyType == "1") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "مؤسسة فردية / تاجر وحيد"
                : "Sole Proprietorship/Sole Trader",
          };
        } else if (this.companyProfileData.company.companyType == "2") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "شراكة عامة"
                : "General Partnership",
          };
        } else if (this.companyProfileData.company.companyType == "3") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)"
                : "Limited Liability Partnership (LLP)",
          };
        } else if (this.companyProfileData.company.companyType == "4") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "شركة ذات مسؤولية محدودة(ش.ذ.م.م)"
                : "Limited Liability Company (LLC)",
          };
        } else if (this.companyProfileData.company.companyType == "5") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "شركة خاصة محدودة"
                : "Private Limited Company",
          };
        } else if (this.companyProfileData.company.companyType == "6") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "شركه عالميه محدوده"
                : "Public Limited Company",
          };
        } else if (this.companyProfileData.company.companyType == "7") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "S Corporation"
                : "S Corporation",
          };
        } else if (this.companyProfileData.company.companyType == "8") {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text:
              this.$store.state.locale.locale == "ar"
                ? "C Corporation"
                : "C Corporation",
          };
        } else {
          this.companyProfileData.company.companyType = {
            value: this.companyProfileData.company.companyType,
            text: this.$store.state.locale.locale == "ar" ? "غير ذلك" : "Other",
          };
        }
      }
      if (this.companyProfileData.company.industries) {
        const industryArray = [];
        const industryLength = this.companyProfileData.company.industries;

        for (var i = 0; i < industryLength.length; i++) {
          industryArray.push(this.companyProfileData.company.industries[i].id);
        }
        this.companyProfileData.company.industries = industryArray;
      }

      if (this.companyProfileData.company.address) {
        this.companyProfileData.company.address.country = {
          value: this.companyProfileData.company.address.country.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.companyProfileData.company.address.country.arabicName
              : this.companyProfileData.company.address.country.text,
        };
        this.companyProfileData.company.address.state = JSON.parse(
          this.companyProfileData.company.address.state
        );
        this.companyProfileData.company.address.state = {
          value: this.companyProfileData.company.address.state.id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.companyProfileData.company.address.state.arabicName !=
                undefined
                ? this.companyProfileData.company.address.state.arabicName
                : this.companyProfileData.company.address.state.name
              : this.companyProfileData.company.address.state.name,
        };
      }
      // if (this.companyProfileData.company.currency) {
      //   this.companyProfileData.company.currency = {
      //     value: this.companyProfileData.company.currency._id,
      //     text: this.companyProfileData.company.currency.text,
      //   };
      // }

      if (this.companyProfileData.company.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          facebook: "",
          twitter: "",
          linkedIn: "",
          otherLinks: "",
        });

        this.companyProfileData.company.socialMedia = socialMedia;
      }
    },

    investorFormated() {
      if (this.investorProfileData0.investor.investorCurrency) {
        this.investorProfileData0.investor.investorCurrency = {
          value: this.investorProfileData0.investor.investorCurrency.value,
          text: this.investorProfileData0.investor.investorCurrency.text,
        };
      }
      if (this.investorProfileData0.investor.investorCategory) {
        this.investorProfileData0.investor.investorCategory = {
          value: this.investorProfileData0.investor.investorCategory.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.investorProfileData0.investor.investorCategory.arabicName
              : this.investorProfileData0.investor.investorCategory.text,
        };
      }
      if (this.investorProfileData0.investor.interstedLocations) {
        const count = this.investorProfileData0.investor.interstedLocations;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push({
            value:
              this.investorProfileData0.investor.interstedLocations[i].value,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.investorProfileData0.investor.interstedLocations[i]
                    .arabicName
                : this.investorProfileData0.investor.interstedLocations[i].text,
          });
        }
        this.investorProfileData0.investor.interstedLocations = interstedArry;
      }
      if (this.investorProfileData0.investor.address) {
        const count = this.investorProfileData0.investor.address;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push({
            value: this.investorProfileData0.investor.address[i].value,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.investorProfileData0.investor.address[i].arabicName
                : this.investorProfileData0.investor.address[i].text,
          });
        }
        this.investorProfileData0.investor.address = interstedArry;
      }
      if (this.investorProfileData0.investor.industries) {
        const count = this.investorProfileData0.investor.industries;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push(
            this.investorProfileData0.investor.industries[i]._id
          );
        }
        this.investorProfileData0.investor.industries = interstedArry;
      }
      if (this.investorProfileData0.investor.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          investorFacebook: "",
          investorTwitter: "",
          investorLinkedIn: "",
          investorOtherLinks: "",
        });

        this.investorProfileData0.investor.socialMedia = socialMedia;
      }
    },
    freelancerFormated() {
      if (this.freelancerProfileData.freelancer.gender) {
        if (this.freelancerProfileData.freelancer.gender == "male") {
          this.freelancerProfileData.freelancer.gender = {
            value: "male",
            text: this.$store.state.locale.locale == "ar" ? "ذكر" : "Male",
          };
        } else {
          this.freelancerProfileData.freelancer.gender = {
            value: "female",
            text: this.$store.state.locale.locale == "ar" ? "أنثى" : "Female",
          };
        }
      }
      if (this.freelancerProfileData.freelancer.nationality) {
        this.freelancerProfileData.freelancer.nationality = {
          value: this.freelancerProfileData.freelancer.nationality.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.freelancerProfileData.freelancer.nationality.arabicName
              : this.freelancerProfileData.freelancer.nationality.text,
        };
      }

      if (this.freelancerProfileData.freelancer.industries) {
        const count = this.freelancerProfileData.freelancer.industries;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push(
            this.freelancerProfileData.freelancer.industries[i]._id
          );
        }
        this.freelancerProfileData.freelancer.industries = interstedArry;
      }

      if (this.freelancerProfileData.freelancer.skills) {
        const count = this.freelancerProfileData.freelancer.skills;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push({
            value: this.freelancerProfileData.freelancer.skills[i]._id,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.freelancerProfileData.freelancer.skills[i].arabicName
                : this.freelancerProfileData.freelancer.skills[i].name,
          });
        }
        this.freelancerProfileData.freelancer.skills = interstedArry;
      }
      if (this.freelancerProfileData.freelancer.locations) {
        this.freelancerProfileData.freelancer.locations.countryId = {
          value:
            this.freelancerProfileData.freelancer.locations[0].countryId.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.freelancerProfileData.freelancer.locations[0].countryId
                  .arabicName
              : this.freelancerProfileData.freelancer.locations[0].countryId
                  .text,
        };
        // this.freelancerProfileData.freelancer.locations.stateId = {
        //   value: this.freelancerProfileData.freelancer.locations[0].stateId,
        //   text: this.freelancerProfileData.freelancer.locations[0].stateId,
        // };

        this.freelancerProfileData.freelancer.locations.stateId = JSON.parse(
          this.freelancerProfileData.freelancer.locations[0].stateId
        );
        this.freelancerProfileData.freelancer.locations.stateId = {
          value: this.freelancerProfileData.freelancer.locations.stateId.id,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.freelancerProfileData.freelancer.locations.stateId
                  .arabicName != undefined
                ? this.freelancerProfileData.freelancer.locations.stateId
                    .arabicName
                : this.freelancerProfileData.freelancer.locations.stateId.name
              : this.freelancerProfileData.freelancer.locations.stateId.name,
        };
      }
      if (this.freelancerProfileData.freelancer.experiences) {
        const count = this.freelancerProfileData.freelancer.experiences;
        const experiencesArry = [];
        for (let i = 0; i < count.length; i++) {
          experiencesArry.push({
            name: this.freelancerProfileData.freelancer.experiences[i].name,
            company:
              this.freelancerProfileData.freelancer.experiences[i].company,
            years: this.freelancerProfileData.freelancer.experiences[i].years,
            location: {
              value:
                this.freelancerProfileData.freelancer.experiences[i].location
                  .value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.freelancerProfileData.freelancer.experiences[i]
                      .location.arabicName
                  : this.freelancerProfileData.freelancer.experiences[i]
                      .location.text,
            },
          });
        }
        this.freelancerProfileData.freelancer.experiences = experiencesArry;
      }
      if (this.freelancerProfileData.freelancer.educations) {
        const count = this.freelancerProfileData.freelancer.educations;
        const educationsArry = [];
        for (let i = 0; i < count.length; i++) {
          educationsArry.push({
            degree: this.freelancerProfileData.freelancer.educations[i].degree,
            institute:
              this.freelancerProfileData.freelancer.educations[i].institute,
            date: this.freelancerProfileData.freelancer.educations[i].date,
            location: {
              value:
                this.freelancerProfileData.freelancer.educations[i].location
                  .value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.freelancerProfileData.freelancer.educations[i].location
                      .arabicName
                  : this.freelancerProfileData.freelancer.educations[i].location
                      .text,
            },
          });
        }
        this.freelancerProfileData.freelancer.educations = educationsArry;
      }

      if (this.freelancerProfileData.freelancer.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          freelancerFacebook: "",
          freelancerTwitter: "",
          freelancerLinkedIn: "",
          freelancerOthers: "",
        });

        this.freelancerProfileData.freelancer.socialMedia = socialMedia;
      }
    },
    brokersFormated() {
      if (this.brokerProfileData.broker.ownerType) {
        if (this.brokerProfileData.broker.ownerType == "individual") {
          this.brokerProfileData.broker.ownerType = {
            value: "individual",
            text:
              this.$store.state.locale.locale == "ar" ? "فرد" : "Individual",
          };
        } else {
          this.brokerProfileData.broker.ownerType = {
            value: "group",
            text: this.$store.state.locale.locale == "ar" ? "مجموعة" : "Group",
          };
        }
      }
      if (this.brokerProfileData.broker.gender) {
        if (this.brokerProfileData.broker.gender == "male") {
          this.brokerProfileData.broker.gender = {
            value: "male",
            text: this.$store.state.locale.locale == "ar" ? "ذكر" : "Male",
          };
        } else {
          this.brokerProfileData.broker.gender = {
            value: "female",
            text: this.$store.state.locale.locale == "ar" ? "أنثى" : "Female",
          };
        }
      }
      if (this.brokerProfileData.broker.nationality) {
        this.brokerProfileData.broker.nationality = {
          value: this.brokerProfileData.broker.nationality.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.brokerProfileData.broker.nationality.arabicName
              : this.brokerProfileData.broker.nationality.text,
        };
      }
      if (this.brokerProfileData.broker.industries) {
        const count = this.brokerProfileData.broker.industries;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push(this.brokerProfileData.broker.industries[i]._id);
        }
        this.brokerProfileData.broker.industries = interstedArry;
      }
      if (this.brokerProfileData.broker.address) {
        const count = this.brokerProfileData.broker.address;
        const addressArry = [];
        for (let i = 0; i < count.length; i++) {
          addressArry.push({
            value: this.brokerProfileData.broker.address[i].value,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.brokerProfileData.broker.address[i].arabicName
                : this.brokerProfileData.broker.address[i].text,
          });
        }
        this.brokerProfileData.broker.address = addressArry;
      }
      if (this.brokerProfileData.broker.experiences) {
        const count = this.brokerProfileData.broker.experiences;
        const experiencesArry = [];
        for (let i = 0; i < count.length; i++) {
          experiencesArry.push({
            name: this.brokerProfileData.broker.experiences[i].name,
            company: this.brokerProfileData.broker.experiences[i].company,
            years: this.brokerProfileData.broker.experiences[i].years,
            location: {
              value:
                this.brokerProfileData.broker.experiences[i].location.value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.brokerProfileData.broker.experiences[i].location
                      .arabicName
                  : this.brokerProfileData.broker.experiences[i].location.text,
            },
          });
        }
        this.brokerProfileData.broker.experiences = experiencesArry;
      }
      if (this.brokerProfileData.broker.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          brokerFacebook: "",
          brokerTwitter: "",
          brokerLinkedIn: "",
          brokerOtherLinks: "",
        });

        this.brokerProfileData.broker.socialMedia = socialMedia;
      }
    },
    advisorFormated() {
      if (this.advisorProfileData.advisor.ownerType) {
        if (this.advisorProfileData.advisor.ownerType == "individual") {
          this.advisorProfileData.advisor.ownerType = {
            value: "individual",
            text:
              this.$store.state.locale.locale == "ar" ? "فرد" : "Individual",
          };
        } else {
          this.advisorProfileData.advisor.ownerType = {
            value: "group",
            text: this.$store.state.locale.locale == "ar" ? "مجموعة" : "Group",
          };
        }
      }
      if (this.advisorProfileData.advisor.gender) {
        if (this.advisorProfileData.advisor.gender == "male") {
          this.advisorProfileData.advisor.gender = {
            value: "male",
            text: this.$store.state.locale.locale == "ar" ? "ذكر" : "Male",
          };
        } else {
          this.advisorProfileData.advisor.gender = {
            value: "female",
            text: this.$store.state.locale.locale == "ar" ? "أنثى" : "Female",
          };
        }
      }
      if (this.advisorProfileData.advisor.industries) {
        const count = this.advisorProfileData.advisor.industries;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push(this.advisorProfileData.advisor.industries[i]._id);
        }
        this.advisorProfileData.advisor.industries = interstedArry;
      }
      if (this.advisorProfileData.advisor.skills) {
        const count = this.advisorProfileData.advisor.skills;
        const skillsArry = [];
        for (let i = 0; i < count.length; i++) {
          skillsArry.push({
            value: this.advisorProfileData.advisor.skills[i]._id,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.advisorProfileData.advisor.skills[i].arabicName
                : this.advisorProfileData.advisor.skills[i].name,
          });
        }
        this.advisorProfileData.advisor.skills = skillsArry;
      }

      if (this.advisorProfileData.advisor.address) {
        const count = this.advisorProfileData.advisor.address;
        const addressArry = [];
        for (let i = 0; i < count.length; i++) {
          addressArry.push({
            value: this.advisorProfileData.advisor.address[i].value,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.advisorProfileData.advisor.address[i].arabicName
                : this.advisorProfileData.advisor.address[i].text,
          });
        }
        this.advisorProfileData.advisor.address = addressArry;
      }
      if (this.advisorProfileData.advisor.experiences) {
        const count = this.advisorProfileData.advisor.experiences;
        const experiencesArry = [];
        for (let i = 0; i < count.length; i++) {
          experiencesArry.push({
            name: this.advisorProfileData.advisor.experiences[i].name,
            company: this.advisorProfileData.advisor.experiences[i].company,
            years: this.advisorProfileData.advisor.experiences[i].years,
            location: {
              value:
                this.advisorProfileData.advisor.experiences[i].location.value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.advisorProfileData.advisor.experiences[i].location
                      .arabicName
                  : this.advisorProfileData.advisor.experiences[i].location
                      .text,
            },
          });
        }
        this.advisorProfileData.advisor.experiences = experiencesArry;
      }

      if (this.advisorProfileData.advisor.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          advisorFacebook: "",
          advisorTwitter: "",
          advisorLinkedIn: "",
          advisorOtherLinks: "",
        });

        this.advisorProfileData.advisor.socialMedia = socialMedia;
      }
    },
    partnerFormated() {
      if (this.partnerProfileData.partner.ownerType) {
        if (this.partnerProfileData.partner.ownerType == "individual") {
          this.partnerProfileData.partner.ownerType = {
            value: "individual",
            text:
              this.$store.state.locale.locale == "ar" ? "فرد" : "Individual",
          };
        } else {
          this.partnerProfileData.partner.ownerType = {
            value: "group",
            text: this.$store.state.locale.locale == "ar" ? "مجموعة" : "Group",
          };
        }
      }
      if (this.partnerProfileData.partner.gender) {
        if (this.partnerProfileData.partner.gender == "male") {
          this.partnerProfileData.partner.gender = {
            value: "male",
            text: this.$store.state.locale.locale == "ar" ? "ذكر" : "Male",
          };
        } else {
          this.partnerProfileData.partner.gender = {
            value: "female",
            text: this.$store.state.locale.locale == "ar" ? "أنثى" : "Female",
          };
        }
      }
      if (this.partnerProfileData.partner.nationality) {
        this.partnerProfileData.partner.nationality = {
          value: this.partnerProfileData.partner.nationality.value,
          text:
            this.$store.state.locale.locale == "ar"
              ? this.partnerProfileData.partner.nationality.arabicName
              : this.partnerProfileData.partner.nationality.text,
        };
      }
      if (this.partnerProfileData.partner.industries) {
        const count = this.partnerProfileData.partner.industries;
        const interstedArry = [];
        for (let i = 0; i < count.length; i++) {
          interstedArry.push(this.partnerProfileData.partner.industries[i]._id);
        }
        this.partnerProfileData.partner.industries = interstedArry;
      }
      if (this.partnerProfileData.partner.address) {
        const count = this.partnerProfileData.partner.address;
        const addressArry = [];
        for (let i = 0; i < count.length; i++) {
          addressArry.push({
            value: this.partnerProfileData.partner.address[i].value,
            text:
              this.$store.state.locale.locale == "ar"
                ? this.partnerProfileData.partner.address[i].arabicName
                : this.partnerProfileData.partner.address[i].text,
          });
        }
        this.partnerProfileData.partner.address = addressArry;
      }

      if (this.partnerProfileData.partner.experiences) {
        const count = this.partnerProfileData.partner.experiences;
        const experiencesArry = [];
        for (let i = 0; i < count.length; i++) {
          experiencesArry.push({
            name: this.partnerProfileData.partner.experiences[i].name,
            company: this.partnerProfileData.partner.experiences[i].company,
            years: this.partnerProfileData.partner.experiences[i].years,
            location: {
              value:
                this.partnerProfileData.partner.experiences[i].location.value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.partnerProfileData.partner.experiences[i].location
                      .arabicName
                  : this.partnerProfileData.partner.experiences[i].location
                      .text,
            },
          });
        }
        this.partnerProfileData.partner.experiences = experiencesArry;
      }
      if (this.partnerProfileData.partner.socialMedia == undefined) {
        const socialMedia = [];
        socialMedia.push({
          partnerFacebook: "",
          partnerTwitter: "",
          partnerLinkedIn: "",
          partnerOtherLinks: "",
        });

        this.partnerProfileData.partner.socialMedia = socialMedia;
      }
    },
  },
  computed: {
    ...mapGetters({
      // categories: "profile/getInvestorCategories",
      // industries: "profile/getAllIndustries",
      // currencies: "currency/getCurrencies",
      // countries: "country/getCountries",
      user: "auth/user",
    }),
  },
  mounted() {
    // this.fetchCategories();
    // this.fetchIndustries();
    // this.fetchCurrencies();
    // this.fetchCountries();
    // this.retreiveProductProfile(this.profileId);
    if (this.user.userType != undefined) {
      try {
        if (this.user.userType === "investor") {
          this.show = true;
          axios
            .get(`investors/${this.user._id}`)
            .then((response) => {
              this.investorProfileData0 = response.data.data[0];
              this.investorProfileData1 = response.data.data[1];
              this.investorFormated();
              // this.formInvestorProfile(this.investorProfileData);
              // this.productProfile.profileIndustries = this.pluck(
              //   this.productProfile.profileIndustries,
              //   "id"
              // );
            })
            .then(() => {
              this.show = false;
            });
        } else if (this.user.userType == "company") {
          this.show = true;
          axios
            .get(`companies/${this.user._id}`)
            .then((response) => {
              this.companyProfileData = response.data.data;
              console.log(this.companyProfileData);
              this.companyFormated();
            })
            .then(() => {
              this.show = false;
            });
        } else if (this.user.userType === "freelancer") {
          this.show = true;
          axios
            .get(`freelancers/${this.user._id}`)
            .then((response) => {
              this.freelancerProfileData = response.data.data;
              this.freelancerFormated();
              // this.investorProfileData1 = response.data.data[1];
              // this.formInvestorProfile(this.investorProfileData);
              // this.productProfile.profileIndustries = this.pluck(
              //   this.productProfile.profileIndustries,
              //   "id"
              // );
            })
            .then(() => {
              this.show = false;
            });
        } else if (this.user.userType === "broker") {
          this.show = true;
          axios
            .get(`brokers/${this.user._id}`)
            .then((response) => {
              this.brokerProfileData = response.data.data[0];
              this.brokersFormated();
            })
            .then(() => {
              this.show = false;
            });
        } else if (this.user.userType === "advisor") {
          this.show = true;
          axios
            .get(`advisors/${this.user._id}`)
            .then((response) => {
              this.advisorProfileData = response.data.data[0];
              this.advisorFormated();
            })
            .then(() => {
              this.show = false;
            });
        } else if (this.user.userType === "partner") {
          this.show = true;
          axios
            .get(`partners/${this.user._id}`)
            .then((response) => {
              this.partnerProfileData = response.data.data[0];
              this.partnerFormated();
            })
            .then(() => {
              this.show = false;
            });
        } else {
          return 0;
        }
      } catch (error) {}
    } else {
      this.user.userType = "";
    }
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "sticky" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
};
</script>
<style scoped>
[dir] label {
}
</style>
