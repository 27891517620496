<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t("FreelancerForm.Freelancer_Details") }}
              </h5>
              <small class="text-muted">
                {{ $t("FreelancerForm.Enter_Freelancer_Details") }}
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Full_Name')"
                label-for="Fomraml_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="Fomraml_name"
                    v-model="freelancerProfile.freelancer.fullName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('FreelancerForm.Full_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Email')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="freelancerProfile.freelancer.emailAddress"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john.doe@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Short_Name')"
                label-for="slugan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="slugan"
                  rules="required"
                >
                  <b-form-input
                    id="slugan"
                    v-model="freelancerProfile.freelancer.legalName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('FreelancerForm.Short_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Phone_Number')"
                label-for="Phone"
              >
                <validation-provider name="phone" rules="required">
                  <VuePhoneNumberInput
                    v-model="
                      freelancerProfile.freelancer.freelancerPhone.phoneNumber
                    "
                    :default-country-code="
                      freelancerProfile.freelancer.freelancerPhone.code
                    "
                    color="#ea5455"
                    error-color="#ea5455"
                    @update="onUpdate"
                    :translations="
                      $store.state.locale.locale == 'ar'
                        ? translationsArabic
                        : translations
                    "
                  />

                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <small v-if="!isValidNumber" class="text-danger">{{
                    $t("common.phone_Invalid")
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.PassportID')"
                label-for="Passport-ID"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Passport ID"
                  rules="required"
                >
                  <b-form-input
                    id="Passport-ID"
                    v-model="freelancerProfile.freelancer.passport"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('FreelancerForm.PassportID')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-group
                  :label="$t('FreelancerForm.Gender')"
                  label-for="Gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Gender"
                    v-model="freelancerProfile.freelancer.gender"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? GenderOptionArabic
                        : GenderOption
                    "
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    freelancerProfile: {},
  },
  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: "",
      phoneCode: "",
      formatAge: [],
      GenderOption: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
      ],
      GenderOptionArabic: [
        { value: "male", text: "ذكر" },
        { value: "female", text: "أنثى" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    // age: {
    //   get() {
    //     if (this.freelancerProfile.freelancer.gender === "male") {
    //       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //       this.formatAge.push({
    //         text: "Male",
    //         value: "male",
    //       });
    //     } else if (this.freelancerProfile.freelancer.gender === "femal") {
    //       // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //       this.formatAge.push({
    //         text: "Female",
    //         value: "female",
    //       });
    //     }
    //     return this.formatAge;
    //   },
    //   set(val) {
    //     this.formatAge.push(val);
    //   },
    // },
  },
  methods: {
    ...mapActions({
      editFreelancer: "profile/editFreelancer",
    }),
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            this.show = true;
            const freelancerData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            freelancerData.append("userId", this.user._id);
            freelancerData.append("userType", "freelancer");
            freelancerData.append(
              "fullName",
              this.freelancerProfile.freelancer.fullName
            );
            freelancerData.append(
              "emailAddress",
              this.freelancerProfile.freelancer.emailAddress
            );
            freelancerData.append(
              "legalName",
              this.freelancerProfile.freelancer.legalName
            );
            freelancerData.append(
              "phoneNumber",
              this.freelancerProfile.freelancer.freelancerPhone.phoneNumber.replace(
                /\s/g,
                ""
              )
            );
            freelancerData.append("phoneCode", this.PhoneCode);
            freelancerData.append(
              "passport",
              this.freelancerProfile.freelancer.passport
            );
            freelancerData.append(
              "gender",
              this.freelancerProfile.freelancer.gender.value
            );
            this.editFreelancer(freelancerData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.error.message;
                this.show = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onUpdate(payload) {
      this.isValidNumber = payload.isValid;
      this.PhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },
  },
  mounted() {
    this.configValidate();
  },
};
</script>
