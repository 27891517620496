<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ $t("BrokerForm.Broker_Info") }}</h5>
              <small class="text-muted">{{
                $t("BrokerForm.Enter_Broker_Info")
              }}</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('BrokerForm.License_details')"
                label-for="licenseDetails"
              >
                <validation-provider
                  #default="{ errors }"
                  name="licenseDetails"
                  rules="required"
                >
                  <b-form-input
                    id="licenseDetails"
                    v-model="brokerProfile.broker.licenseDetails"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('BrokerForm.License_details')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                rules="required"
              >
                <b-form-group
                  :label="$t('BrokerForm.Your_nationality')"
                  label-for="Nationality"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Nationality"
                    v-model="brokerProfile.broker.nationality"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group
                  :label="$t('BrokerForm.industries_company_belong')"
                  label-for="Industry"
                  :state="errors.length > 0 ? false : null"
                >
                  <div id="app">
                    <treeselect
                      v-model="brokerProfile.broker.industries"
                      :multiple="true"
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  :label="$t('BrokerForm.Your_country')"
                  label-for="Country"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Country"
                    multiple
                    v-model="brokerProfile.broker.address"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('BrokerForm.Brief_overview_yourself')"
                label-for="Highlights"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Highlights"
                  :rules="`required|max:${$maxHighlightsUser}`"
                >
                  <b-form-textarea
                    id="Highlights"
                    v-model="brokerProfile.broker.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('BrokerForm.Brief_overview_yourself')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('BrokerForm.description_yourself')"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="brokerProfile.broker.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('BrokerForm.description_yourself')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('BrokerForm.description_previous_work')"
                label-for="previousWork"
              >
                <validation-provider
                  #default="{ errors }"
                  name="previousWork"
                  rules="required"
                >
                  <b-form-textarea
                    id="previousWork"
                    v-model="brokerProfile.broker.previousWork"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('BrokerForm.description_previous_work')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Experience from "@/views/forms/form-repeater/ExperiencRepeater.vue";
import Institute from "@/views/forms/form-repeater/CertificationRepeater.vue";
import Certificate from "@/views/forms/form-repeater/EducationRepeater.vue";
import FormRepeaterBasic from "@/views/forms/form-repeater/LanguageRepeater.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Experience,
    Institute,
    Certificate,
    FormRepeaterBasic,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    Treeselect,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    brokerProfile: {},
  },
  data() {
    return {
      show: false,
      errorMessage: "",
      isValidNumber: null,
      // formatInd: [],
      country: "",
      state: "",
    };
  },
  computed: {
    // formateIndustries: {
    //   get() {
    //     this.freelancerProfile.freelancer.industries.forEach((element) => {
    //       this.formatInd.push(element._id);
    //     });
    //     return this.formatInd;
    //   },
    //   set(val) {
    //     this.formatInd = val;
    //   },
    // },
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      // countries: "country/getFilterCountries",
      countries: "country/getCountries",

      user: "auth/user",
      skills: "profile/getAllSkills",
      statesOptions: "country/getAllStates",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.fetchSkills();
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      editBroker: "profile/editBroker",
      fetchCountries: "country/retreiveCountries",
      fetchSkills: "profile/retreiveSkills",
      fetchStates: "country/retreiveStates",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.brokerProfile.broker.industries.length = 6;
      }
    },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        resolve(true);
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const formatIndustries = [];
            const formatSkills = [];
            const formatLocation = [];

            if (this.brokerProfile.broker.industries) {
              this.brokerProfile.broker.industries.forEach((element) => {
                formatIndustries.push({
                  _id: element,
                });
              });
            }

            this.brokerProfile.broker.address.forEach((element) => {
              formatLocation.push(element.value);
            });

            const brokerData = new FormData();
            brokerData.append("userId", this.user._id);
            brokerData.append("userType", "broker");
            brokerData.append(
              "licenseDetails",
              this.brokerProfile.broker.licenseDetails
            );
            brokerData.append(
              "nationality",
              this.brokerProfile.broker.nationality.value
            );
            brokerData.append(
              "industries",
              JSON.stringify(this.brokerProfile.broker.industries)
            );
            brokerData.append("address", JSON.stringify(formatLocation));

            brokerData.append(
              "highlights",
              this.brokerProfile.broker.highlights
            );
            brokerData.append(
              "description",
              this.brokerProfile.broker.description
            );
            brokerData.append(
              "previousWork",
              this.brokerProfile.broker.previousWork
            );

            this.editBroker(brokerData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.error.message;
                this.show = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
