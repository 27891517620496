<template>
  <b-card :title="$t('common.Languages')">
    <div>
      <b-form ref="form">
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col cols="12" md="4">
            <b-form-group>
              <v-select
                v-model="item.name"
                label="text"
                :options="languages"
                style="color: black"
              />
            </b-form-group>
          </b-col>

          <!-- level -->
          <b-col cols="12" md="4">
            <b-form-rating
              v-model="item.level"
              no-border
              show-clear
              show-value
              inline
              variant="warning"
            />
          </b-col>
          <!-- Remove Button -->
          <b-col cols="12" md="2" sm="6" class="pt-0 mt-0 mb-60">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
                @click="removeItem(index)"
              />
              <span>{{ $t("common.Delete") }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t("common.AddNew") }}</span>
    </b-button>
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormRating,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import languages from "@/store/country/nationality.json";

export default {
  components: {
    BCardCode,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: ["items"],
  data() {
    return {
      languages,
      nextTodoId: 2,
      name: {
        text: "",
        value: "",
      },
      level: 5,
    };
  },
  computed: {
    ...mapGetters({
      countries: "country/getCountries",
    }),
  },
  mounted() {
    this.initTrHeight();
    this.fetchCountries();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    ...mapActions({
      fetchCountries: "country/retreiveCountries",
    }),
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
        level: this.level,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
