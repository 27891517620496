<template>
  <b-card>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t("FreelancerForm.About_you") }}
              </h5>
              <small class="text-muted">
                {{ $t("common.Your_language") }}
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group
                label=""
                label-for="language"
              >
                <validation-provider
                  #default="{ errors }"
                  name="language"
                  rules=""
                >
                  <LanguageRepeater :items="freelancerProfile" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
              >{{ errorMessage }}</b-alert>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// import LanguageRepeater from "../../../forms/form-repeater/LanguageRepeater.vue";
import LanguageRepeater from './repeater/LanguageRepeater.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    LanguageRepeater,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    freelancerProfile: {},
  },
  data() {
    return {
      show: false,

      errorMessage: '',
      isValidNumber: null,
      phoneNumber: '',
      phoneCode: '',
      formatAge: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      editFreelancer: 'profile/editFreelancer',
    }),
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            this.show = true
            const formatLanguage = []
            this.freelancerProfile.forEach(element => {
              formatLanguage.push({
                name:
                  typeof element.name === 'string'
                    ? element.name
                    : element.name.text,
                level: element.level,
              })
            })

            const freelancerData = new FormData()
            // eslint-disable-next-line no-underscore-dangle
            freelancerData.append('userId', this.user._id)
            freelancerData.append('userType', 'freelancer')
            freelancerData.append('languages', JSON.stringify(formatLanguage))

            this.editFreelancer(freelancerData)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('messages.Modified_successfully'),
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.show = false
              })
              .catch(error => {
                this.errorMessage = error.response.data.error.message
                this.show = false
              })
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
