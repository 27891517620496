<template>
  <b-card v-if="freelancerProfile">
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ $t("FreelancerForm.Freelancer_Info") }}</h5>
              <small class="text-muted">{{
                $t("FreelancerForm.Enter_Freelancer_Info")
              }}</small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Nationality"
                rules="required"
              >
                <b-form-group
                  :label="$t('FreelancerForm.Your_nationality')"
                  label-for="Nationality"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Nationality"
                    v-model="freelancerProfile.freelancer.nationality"
                    :options="countries"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group
                  :label="$t('FreelancerForm.industries_belong_to')"
                  label-for="Industry"
                  :state="errors.length > 0 ? false : null"
                >
                  <div id="app">
                    <treeselect
                      v-model="freelancerProfile.freelancer.industries"
                      :multiple="true"
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules=""
              >
                <b-form-group
                  :label="$t('FreelancerForm.Your_country')"
                  label-for="Country"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Country"
                    v-model="freelancerProfile.freelancer.locations.countryId"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(
                        freelancerProfile.freelancer.locations.countryId.value
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="State" rules="">
                <b-form-group
                  :label="$t('FreelancerForm.Your_state')"
                  label-for="State"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="State"
                    v-model="freelancerProfile.freelancer.locations.stateId"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Skills"
                rules="required"
              >
                <b-form-group
                  :label="$t('FreelancerForm.Your_skills')"
                  label-for="SkillsID"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="SkillsID"
                    v-model="freelancerProfile.freelancer.skills"
                    :options="skills"
                    multiple
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Brief_overview_yourself')"
                label-for="Highlights"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Highlights"
                  rules="required"
                >
                  <b-form-textarea
                    id="Highlights"
                    v-model="freelancerProfile.freelancer.highlights"
                    :state="errors.length > 0 ? false : null"
                    placeholder=" Brief overview of yourself"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.description_yourself')"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="freelancerProfile.freelancer.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('FreelancerForm.description_yourself')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Experience from "@/views/forms/form-repeater/ExperiencRepeater.vue";
import Institute from "@/views/forms/form-repeater/CertificationRepeater.vue";
import Certificate from "@/views/forms/form-repeater/EducationRepeater.vue";
import FormRepeaterBasic from "@/views/forms/form-repeater/LanguageRepeater.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Experience,
    Institute,
    Certificate,
    FormRepeaterBasic,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    Treeselect,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    freelancerProfile: {},
  },
  data() {
    return {
      show: false,
      errorMessage: "",
      isValidNumber: null,
      // formatInd: [],
      country: "",
      state: "",
    };
  },
  computed: {
    // formateIndustries: {
    //   get() {
    //     this.freelancerProfile.freelancer.industries.forEach((element) => {
    //       this.formatInd.push(element._id);
    //     });
    //     return this.formatInd;
    //   },
    //   set(val) {
    //     this.formatInd = val;
    //   },
    // },
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      // countries: "country/getFilterCountries",
      countries: "country/getCountries",

      user: "auth/user",
      skills: "profile/getAllSkills",
      statesOptions: "country/getAllStates",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.fetchSkills();

    // this.retreiveStates(
    //   this.freelancerProfile.freelancer.locations.countryId.value
    // );
  },

  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      editFreelancer: "profile/editFreelancer",
      fetchCountries: "country/retreiveCountries",
      fetchSkills: "profile/retreiveSkills",
      fetchStates: "country/retreiveStates",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        resolve(true);
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const formatIndustries = [];
            const formatSkills = [];
            const formatLocation = [];

            if (this.freelancerProfile.freelancer.industries) {
              this.freelancerProfile.freelancer.industries.forEach(
                (element) => {
                  formatIndustries.push({
                    _id: element,
                  });
                }
              );
            }
            if (this.freelancerProfile.freelancer.skills) {
              this.freelancerProfile.freelancer.skills.forEach((element) => {
                formatSkills.push({
                  _id: element.value,
                });
              });
              console.log(formatSkills);
            }

            formatLocation.push({
              stateId:
                typeof this.freelancerProfile.freelancer.locations.stateId ===
                "string"
                  ? this.freelancerProfile.freelancer.locations.stateId
                  : this.freelancerProfile.freelancer.locations.stateId.value,
              countryId:
                this.freelancerProfile.freelancer.locations.countryId.value,
            });

            const freelancerData = new FormData();
            freelancerData.append("userId", this.user._id);
            freelancerData.append("userType", "freelancer");
            freelancerData.append(
              "nationality",
              this.freelancerProfile.freelancer.nationality.value
            );
            freelancerData.append(
              "industries",
              JSON.stringify(formatIndustries)
            );
            freelancerData.append("locations", JSON.stringify(formatLocation));

            freelancerData.append("skills", JSON.stringify(formatSkills));
            freelancerData.append(
              "highlights",
              this.freelancerProfile.freelancer.highlights
            );
            freelancerData.append(
              "description",
              this.freelancerProfile.freelancer.description
            );

            this.editFreelancer(freelancerData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.error.message;
                this.show = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
