<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <validation-observer ref="simpleRules">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.Your_Designation')"
                label-for="Designation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Designation"
                  rules="required"
                >
                  <b-form-input
                    id="Designation"
                    v-model="investorProfile.investor.designation"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Your_Designation')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Interested "
                rules="required"
              >
                <b-form-group
                  :label="$t('InvestorForm.interestedIn')"
                  label-for="Interested"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Interested"
                    v-model="investorProfile.investor.investorCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Investor Role "
                rules="required"
              >
                <b-form-group
                  :label="$t('InvestorForm.Investor_Role')"
                  label-for="Investor-Role"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-select
                    id="Investor-Role"
                    v-model="investorProfile.investor.investorRole"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createInvestorProfile.InvestorRoleOptionArabic
                        : createInvestorProfile.InvestorRoleOption
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group
                  :label="$t('InvestorForm.industries_investment_belong')"
                  label-for="Industry"
                  :state="errors.length > 0 ? false : null"
                >
                  <div id="app">
                    <treeselect
                      v-model="investorProfile.investor.industries"
                      :multiple="true"
                      :placeholder="$t('common.Select')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <!-- <v-select
                    id="Industry"
                    v-model="createInvestorProfile.selectedIndustry"
                    
                    :options="createInvestorProfile.IndustryOption"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  /> -->
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  :label="$t('InvestorForm.Desired_Investing_Addresses')"
                  label-for="Country"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Country"
                    v-model="investorProfile.investor.interstedLocations"
                    multiple
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.Company_Name')"
                label-for="Company-Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="Company-Name"
                    v-model="investorProfile.investor.companyName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Company_Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  :label="$t('InvestorForm.Investment_Country')"
                  label-for="Country"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Country"
                    v-model="investorProfile.investor.address"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    multiple
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.Business_Factors')"
                label-for="Business-Factors"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Factors"
                  rules="required"
                >
                  <b-form-textarea
                    id="Business-Factors"
                    v-model="investorProfile.investor.businessFactors"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Business_Factors')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="Currency"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label class="required" for="Currency">{{
                    $t("InvestorForm.Currency")
                  }}</label>

                  <v-select
                    id="Currency"
                    v-model="investorProfile.investor.investorCurrency"
                    :options="currencies"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('InvestorForm.Investment_Min_Size')"
                label-for="Investment-Min-Size"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Investment Min Size"
                  rules="required|integer"
                >
                  <b-form-input
                    id="Investment-Min-Size"
                    v-model="investorProfile.investor.investmentSize.min"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Investment_Min_Size')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('InvestorForm.Investment_Max_Size')"
                label-for="Investment-Max-Size"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Investment Max Size"
                  rules="required|integer"
                >
                  <b-form-input
                    id="Investment-Size"
                    v-model="investorProfile.investor.investmentSize.max"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Investment_Max_Size')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.Brief_overview_investment')"
                label-for="Highlights"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Highlights"
                  :rules="`required|max:${$maxHighlightsUser}`"
                >
                  <b-form-textarea
                    id="Highlights"
                    v-model="investorProfile.investor.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.Brief_overview_investment')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.description')"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="investorProfile.investor.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.description')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('InvestorForm.About_your_company')"
                label-for="Company-Description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Description"
                  rules="required"
                >
                  <b-form-textarea
                    id="Company-Description"
                    v-model="investorProfile.investor.companyDescription"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('InvestorForm.About_your_company')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";

import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormSelect,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    BFormInvalidFeedback,
    BFormSelect,
    Treeselect,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  data() {
    return {
      show: false,
      //   optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,

      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: "",
      phoneCode: "",
      selectedIndustry: [],
      createInvestorProfile: {
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        BusinessFactors: "",
        Designation: "",
        SelectedInterested: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        SelectedInvestorRole: "",
        Highlights: "",
        description: "",
        CompanyName: "",
        CompanyDescription: "",
        selectedDesiredCountry: [],
        selectedSaleType: "",
        SelectedCurrency: "",
        InvestmentMinSize: "",
        InvestmentMaxSize: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: [],
        pincode: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        ProfilePicture: [],
        CompanyLogo: [],
        ProofBusiness: [],
        CorporateProfile: [],
        InvestorRoleOption: [
          { value: "1", text: "Individual Investor / Buyer" },
          { value: "2", text: "Corporate Investor / Buyer" },
          {
            label: "Lender",
            options: [
              { value: "3", text: "Bank" },
              { value: "4", text: "Financial Institution" },
            ],
          },
          {
            label: "Financial Advisor",
            options: [
              { value: "5", text: "Accounting Firm" },
              { value: "6", text: "Business Broker" },
              { value: "7", text: "Financial Consultant" },
              { value: "8", text: "Investment Bank" },
              { value: "9", text: "Law Firm" },
              { value: "10", text: "M&A Advisor" },
              { value: "11", text: "Merchant Bank" },
              { value: "12", text: "Commercial Real Estate Broker" },
            ],
          },
          {
            label: "Fund",
            options: [
              { value: "13", text: "Venture Capital Firm" },
              { value: "14", text: "Private Equity Firm" },
              { value: "15", text: "Family Office" },
              { value: "16", text: "Hedge Fund" },
              { value: "17", text: "Search Fund" },
            ],
          },
        ],
        InvestorRoleOptionArabic: [
          { value: "1", text: "مستثمر/مشتري فردي" },
          { value: "2", text: "مستثمر/مشتري شركة" },
          {
            label: "المُقرض",
            options: [
              { value: "3", text: "مصرف" },
              { value: "4", text: "مؤسسة مالية" },
            ],
          },
          {
            label: "مستشار مالي",
            options: [
              { value: "5", text: "شركة محاسبة" },
              { value: "6", text: "وسيط أعمال" },
              { value: "7", text: "مستشار مالي" },
              { value: "8", text: "بنك استثمار" },
              { value: "9", text: "شركة محاماة" },
              { value: "10", text: "مستشار الاندماج والاستحواذ" },
              { value: "11", text: "بنك تجاري" },
              { value: "12", text: "وسيط عقاري تجاري" },
            ],
          },
          {
            label: "تمويل",
            options: [
              { value: "13", text: "شركة رأس المال الاستثماري" },
              { value: "14", text: "شركة الاسهم الخاصة" },
              { value: "15", text: "مكتب العائلة" },
              { value: "16", text: "صندوق التحوط" },
              { value: "17", text: "صندوق البحث" },
            ],
          },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Open Auction" },
          { value: "3", text: "Timed Auction" },
        ],
      },
    };
  },
  props: {
    investorProfile: {},
  },
  computed: {
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      //   createInvestor: "profile/createInvestor",
      fetchCountries: "country/retreiveCountries",
      editInvestor: "profile/editInvestor",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.investorProfile.investor.industries.length = 6;
      }
    },
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const userData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            userData.append("userId", this.user._id);
            userData.append("userType", "investor");
            userData.append(
              "designation",
              this.investorProfile.investor.designation
            );
            userData.append(
              "investorCategory",
              this.investorProfile.investor.investorCategory.value
            );
            userData.append(
              "interestedCountry",
              JSON.stringify(
                this.pluck(
                  this.investorProfile.investor.interstedLocations,
                  "value"
                )
              )
            );
            userData.append(
              "address",
              JSON.stringify(
                this.pluck(this.investorProfile.investor.address, "value")
              )
            );

            userData.append(
              "investorRole",
              this.investorProfile.investor.investorRole
            );

            userData.append(
              "industries",
              JSON.stringify(this.investorProfile.investor.industries)
            );
            userData.append(
              "companyName",
              this.investorProfile.investor.companyName
            );
            userData.append(
              "businessFactors",
              this.investorProfile.investor.businessFactors
            );
            userData.append(
              "investorCurrency",
              this.investorProfile.investor.investorCurrency.value
            );
            userData.append(
              "minimumInvestment",
              this.investorProfile.investor.investmentSize.min
            );
            userData.append(
              "maximumInvestment",
              this.investorProfile.investor.investmentSize.max
            );
            userData.append(
              "highlights",
              this.investorProfile.investor.highlights
            );
            userData.append(
              "description",
              this.investorProfile.investor.description
            );
            userData.append(
              "companyDescription",
              this.investorProfile.investor.companyDescription
            );

            this.editInvestor(userData)
              .then((response) => {
                // console.log(response.data.message);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                this.errorMessage = error.response.data.error.message;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    // this.retreiveProductProfile(this.profileId);

    // axios.get(`profiles/${this.$route.params.id}`).then((response) => {
    //   this.productProfile = response.data.data;
    //   this.getProductProfile();
    //   this.productProfile.profileIndustries = this.pluck(
    //     this.productProfile.profileIndustries,
    //     "id"
    //   );
    // });
  },
  //   setup() {
  //     const refInputEl = ref(null);
  //     const previewEl = ref(null);

  //     const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

  //     return {
  //       refInputEl,
  //       previewEl,
  //       inputImageRenderer,
  //     };
  //   },
};
</script>
