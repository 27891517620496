var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company image","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"profile-picture"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.main_company_image"))+" "),_c('i',{staticClass:"fa-solid fa-circle-info",attrs:{"id":"CompanyImage-popover"}})]),_c('b-popover',{attrs:{"target":"CompanyImage-popover","title":"","placement":"top","triggers":"hover focus","content":_vm.$t('companyPopover.CompanyImage')}}),_c('b-form-file',{attrs:{"required":"","accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here...","multiple":false},model:{value:(_vm.createCompanyProfile.ProfilePicture),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "ProfilePicture", $$v)},expression:"createCompanyProfile.ProfilePicture"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Company-Documents"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.company_documents"))+" "),_c('i',{staticClass:"fa-solid fa-circle-info",attrs:{"id":"CompanyDocuments-popover"}})]),_c('b-popover',{attrs:{"target":"CompanyDocuments-popover","title":"","placement":"top","triggers":"hover focus","content":_vm.$t('companyPopover.CompanyDocuments')}}),_c('validation-provider',{attrs:{"name":"Company Documents","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createCompanyProfile.CompanyDocuments),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "CompanyDocuments", $$v)},expression:"createCompanyProfile.CompanyDocuments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"Proof-Business"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.proof_of_business"))+" "),_c('i',{staticClass:"fa-solid fa-circle-info",attrs:{"id":"CompanyProof-popover"}})]),_c('b-popover',{attrs:{"target":"CompanyProof-popover","title":"","placement":"top","triggers":"hover focus","content":_vm.$t('companyPopover.CompanyProof')}}),_c('validation-provider',{attrs:{"name":"Company Proof","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createCompanyProfile.ProofBusiness),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "ProofBusiness", $$v)},expression:"createCompanyProfile.ProofBusiness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"company-photos"}},[_vm._v(_vm._s(_vm.$t("CompanyForm.company_images"))+" "),_c('i',{staticClass:"fa-solid fa-circle-info",attrs:{"id":"companyPhotos-popover"}})]),_c('b-popover',{attrs:{"target":"companyPhotos-popover","title":"","placement":"top","triggers":"hover focus","content":_vm.$t('companyPopover.companyPhotos')}}),_c('validation-provider',{attrs:{"name":"Company Photos","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.createCompanyProfile.CompanyPhotos),callback:function ($$v) {_vm.$set(_vm.createCompanyProfile, "CompanyPhotos", $$v)},expression:"createCompanyProfile.CompanyPhotos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.SaveChanges"))+" ")])],1)],1)],1)],1),_c('hr'),_c('br'),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.companyProfile.company.files,"fields":_vm.$store.state.locale.locale == 'ar' ? _vm.fieldsArabic : _vm.fields},scopedSlots:_vm._u([{key:"cell(assets)",fn:function(data){return [_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"images"},[(data.item.type.includes('image'))?_c('b-avatar',{attrs:{"variant":"primary","src":data.item.fileUrl,"text":"BV"}}):_vm._e()],1),(data.item.type.includes('application'))?_c('a',{attrs:{"href":data.item.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("dashboard.Preview")))]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.deleteFileById(data.item._id)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.Delete"))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }