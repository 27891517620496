<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <b-form class="mt-2">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ $t("FreelancerForm.Social_Links") }}</h5>
              <small class="text-muted">
                {{ $t("FreelancerForm.Enter_Social_Links") }}
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="freelancerProfile.freelancerFacebook"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Twitter')"
                label-for="twitter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Twitter"
                  rules="url"
                >
                  <b-form-input
                    id="twitter"
                    v-model="freelancerProfile.freelancerTwitter"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://twitter.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="freelancerProfile.freelancerLinkedIn"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('FreelancerForm.Website')"
                label-for="Other"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="freelancerProfile.freelancerOthers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-alert
                v-if="errorMessage"
                show
                variant="warning"
                class="warning"
                style="color: red"
                >{{ errorMessage }}</b-alert
              >
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BForm,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BCard,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BFormGroup,
    BAlert,
    ToastificationContent,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    freelancerProfile: {},
  },
  data() {
    return {
      show: false,
      errorMessage: "",
      isValidNumber: null,
      phoneNumber: "",
      phoneCode: "",
      formatAge: [],
      GenderOption: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      editFreelancer: "profile/editFreelancer",
    }),
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const freelancerData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            freelancerData.append("userId", this.user._id);

            freelancerData.append("userType", "freelancer");

            if (this.freelancerProfile.freelancerFacebook) {
              freelancerData.append(
                "freelancerFacebook",
                this.freelancerProfile.freelancerFacebook
              );
            }

            if (this.freelancerProfile.freelancerLinkedIn) {
              freelancerData.append(
                "freelancerLinkedIn",
                this.freelancerProfile.freelancerLinkedIn
              );
            }

            if (this.freelancerProfile.freelancerTwitter) {
              freelancerData.append(
                "freelancerTwitter",
                this.freelancerProfile.freelancerTwitter
              );
            }
            if (this.freelancerProfile.freelancerOthers) {
              freelancerData.append(
                "freelancerOthers",
                this.freelancerProfile.freelancerOthers
              );
            }

            this.editFreelancer(freelancerData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.error.message;
                this.show = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>
