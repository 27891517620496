<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Personal Photo"
                  :rules="`size:${$sizeFilesUser}`"
                >
                  <label for="PersonalPhoto"
                    >{{ $t("FreelancerForm.Personal_Photo") }}
                  </label>
                  <b-form-file
                    id="PersonalPhoto"
                    v-model="createFreelancerProfile.PersonalPhoto"
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Freelancer Prove"
                  :rules="`size:${$sizeFilesUser}`"
                >
                  <label for="Freelancer-Prove"
                    >{{ $t("FreelancerForm.Freelancer_Prove") }}
                  </label>
                  <b-form-file
                    id="Freelancer-Prove"
                    v-model="createFreelancerProfile.FreelancerProve"
                    accept="image/jpeg, image/png, image/gif"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    :multiple="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="certificates"
                  :rules="`size:${$sizeFilesUser}`"
                >
                  <label for="Freelancer-certificates"
                    >{{ $t("FreelancerForm.your_certificates") }}
                  </label>
                  <b-form-file
                    id="Freelancer-certificates"
                    v-model="createFreelancerProfile.FreelancerCertificates"
                    accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="relevant docouments"
                  :rules="`size:${$sizeFilesUser}`"
                >
                  <label for="relevant-Docouments"
                    >{{ $t("FreelancerForm.relevant_Docouments") }}
                  </label>
                  <b-form-file
                    id="relevant-Docouments"
                    v-model="createFreelancerProfile.FreelancerRelevant"
                    accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('common.Choose_file_drop')"
                    :browse-text="$t('common.Browse')"
                    drop-placeholder="Drop file here..."
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <hr />
      <br />
      <b-col cols="12">
        <b-table
          striped
          hover
          :items="freelancerProfile.files"
          :fields="$store.state.locale.locale == 'ar' ? fieldsArabic : fields"
        >
          <template #cell(assets)="data">
            <div v-viewer class="images">
              <b-avatar
                v-if="data.item.type.includes('image')"
                variant="primary"
                :src="data.item.fileUrl"
                text="BV"
              />
            </div>
            <a
              v-if="data.item.type.includes('application')"
              :href="data.item.fileUrl"
              target="_blank"
              >{{ $t("dashboard.Preview") }}</a
            >
          </template>
          <template #cell(actions)="data">
            <b-button
              small
              variant="danger"
              @click="deleteFileById(data.item._id)"
            >
              {{ $t("dashboard.Delete") }}
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormInvalidFeedback,
  BTable,
  BAvatar,
  BPopover,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent,
    BFormInvalidFeedback,
    BTable,
    BAvatar,
    BPopover,
    VueViewer,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    freelancerProfile: {},
  },
  data() {
    return {
      show: false,
      boxTwo: "",
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "assets",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      fieldsArabic: [
        {
          label: "اسم الملف",
          key: "name",
          sortable: true,
        },
        {
          label: "الملف",
          key: "assets",
          sortable: false,
        },
        {
          label: "الإجراء",
          key: "actions",
          sortable: false,
        },
      ],
      //   optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,

      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: "",
      phoneCode: "SY",
      selectedIndustry: [],
      createFreelancerProfile: {
        PersonalPhoto: null,
        FreelancerProve: null,
        FreelancerCertificates: [],
        FreelancerRelevant: [],
      },
      companyPopover: {
        EBITDA:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        MonthlySales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        AnnualSales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        CompanyImage:
          "Kindly upload your facility / office pictures as profiles with photos get higher rating and visibility. You can upload jpg, bmp, png, tiff, gif files which are less than 3MB in size.",
        CompanyDocuments:
          "Profiles with documents get higher rating and visibility. Documents uploaded are accessible only to members introduced to you. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif, mp4 files which are less than 3MB in size.",
        CompanyProof:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
        companyPhotos:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      editFreelancer: "profile/editFreelancer",
      deleteUserFile: "profile/deleteUserFile",
    }),
    deleteFileById(id) {
      //   deleteData.append("profileId", this.serviceProfile._id);
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.Please_confirm_delete"), {
          title: this.$t("messages.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const deleteData = new FormData();
            deleteData.append("fileId", id);
            this.deleteUserFile(deleteData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("messages.Deleted_successfully"),
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.freelancerProfile.files =
                  this.freelancerProfile.files.filter((data) => data._id != id);
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.error.message,
                    icon: "CoffeeIcon",
                    variant: "error",
                  },
                });
                // this.$router.push({ name: "idea-listAll" });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const userData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            userData.append("userId", this.user._id);
            userData.append("userType", "freelancer");
            if (this.createFreelancerProfile.PersonalPhoto) {
              userData.append(
                "personalPhoto",
                this.createFreelancerProfile.PersonalPhoto
              );
            }
            if (this.createFreelancerProfile.FreelancerProve) {
              userData.append(
                "freelancerProves",
                this.createFreelancerProfile.FreelancerProve
              );
            }
            if (this.createFreelancerProfile.FreelancerCertificates) {
              for (const i of Object.keys(
                this.createFreelancerProfile.FreelancerCertificates
              )) {
                userData.append(
                  "certificates",
                  this.createFreelancerProfile.FreelancerCertificates[i]
                );
              }
            }
            if (this.createFreelancerProfile.FreelancerRelevant) {
              for (const i of Object.keys(
                this.createFreelancerProfile.FreelancerRelevant
              )) {
                userData.append(
                  "otherDocuments",
                  this.createFreelancerProfile.FreelancerRelevant[i]
                );
              }
            }
            this.editFreelancer(userData)
              .then((response) => {
                // console.log(response.data.message);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Added_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.errorMessage = error.response.data.error.message;
                this.show = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    // resetForm() {
    //   this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    // },
  },
};
</script>
