<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-overlay :show="show" rounded="sm">
        <!-- form -->
        <b-form>
          <b-row>
            <!-- old password -->
            <b-col md="6">
              <b-form-group
                :label="$t('dashboard.OldPassword')"
                label-for="account-old-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Old Password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="passwordValueOld"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      :placeholder="$t('dashboard.OldPassword')"
                      required
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ old password -->
          </b-row>
          <b-row>
            <!-- new password -->
            <b-col md="6">
              <b-form-group
                label-for="account-new-password"
                :label="$t('dashboard.NewPassword')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  vid="Password"
                  rules="required|min:8|password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="newPasswordValue"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      :placeholder="$t('dashboard.NewPassword')"
                      required
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
              <b-form-group
                label-for="account-retype-new-password"
                :label="$t('dashboard.RetypePassword')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="RetypePassword"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      :placeholder="$t('dashboard.RetypePassword')"
                      required
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="mt-1"
              >
                {{ $t("dashboard.Reset") }}
              </b-button>
            </b-col>
            <!--/ buttons -->
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      editPassword: "auth/editPassword",
    }),
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;

          const formData = new FormData();

          formData.append("oldPassword", this.passwordValueOld);
          formData.append("newPassword", this.newPasswordValue);
          formData.append("confirmNewPassword", this.RetypePassword);
          this.editPassword(formData)

            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("messages.Modified_successfully"),
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.passwordValueOld = "";
              this.newPasswordValue = "";
              this.RetypePassword = "";
              this.show = false;
            })
            .catch((error) => {
              this.show = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.error.message,
                  icon: "CoffeeIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>
