<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.Company_country')"
                  label-for="Country"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Country"
                    v-model="companyProfile.company.address.country"
                    :options="countries"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    label="text"
                    @input="
                      retreiveStates(
                        companyProfile.company.address.country.value
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.Company_state')"
                  label-for="State"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="State"
                    v-model="companyProfile.company.address.state"
                    :options="statesOptions"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.Company_city')"
                label-for="City"
              >
                <validation-provider #default="{ errors }" name="City" rules="">
                  <b-form-input
                    id="City"
                    v-model="companyProfile.company.address.city"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.Company_city')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.Company_street')"
                label-for="otherInfo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="otherInfo"
                  rules=""
                >
                  <b-form-input
                    id="otherInfo"
                    v-model="companyProfile.company.address.street"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.Company_street')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent,
    vSelect,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    companyProfile: {},
  },
  data() {
    return {
      show: false,
      //   optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,

      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: "",
      phoneCode: "SY",
      selectedIndustry: [],
      createCompanyProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        Establishment: "",
        EBITDA: "",
        MonthlySales: "",
        AnnualSales: "",
        PhysicalAssets: "",
        Assets: "",
        LicenseNumber: "",
        SelectedCompanyType: "",
        SelectedCategories: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        city: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedIndustry: "",
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: "",
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        companyPhoneCode: "",
        ProfilePicture: null,
        CompanyDocuments: null,
        ProofBusiness: null,
        CompanyPhotos: null,
        CompanyTypeOption: [
          { value: "1", text: "Sole Proprietorship/Sole Trader" },
          { value: "2", text: "General Partnership" },
          { value: "3", text: "Limited Liability Partnership (LLP)" },
          { value: "4", text: "Limited Liability Company (LLC)" },
          { value: "5", text: "Private Limited Company" },
          { value: "6", text: "Public Limited Company" },
          { value: "7", text: "S Corporation" },
          { value: "8", text: "C Corporation" },
          { value: "9", text: "Other" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Open Auction" },
          { value: "3", text: "Timed Auction" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
    }),
  },
  mounted() {
    this.fetchCountries();
  },
  methods: {
    ...mapActions({
      editCompany: "profile/editCompany",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
    }),
    retreiveStates(data) {
      this.fetchStates(data);
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const userData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            userData.append("userId", this.user._id);
            userData.append("userType", "company");
            userData.append(
              "companyCountry",
              this.companyProfile.company.address.country.value
            );
            userData.append(
              "companyState",
              this.companyProfile.company.address.state.value
            );
            userData.append(
              "compnayCity",
              this.companyProfile.company.address.city
            );
            userData.append(
              "companyStreet",
              this.companyProfile.company.address.street
            );

            this.editCompany(userData)
              .then((response) => {
                // console.log(response.data.message);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                this.errorMessage = error.response.data.error.message;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    // resetForm() {
    //   this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    // },
  },
};
</script>
