var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Full_Name'),"label-for":"Fomraml_name"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Fomraml_name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Full_Name')},model:{value:(_vm.investorProfile.investor.legalName),callback:function ($$v) {_vm.$set(_vm.investorProfile.investor, "legalName", $$v)},expression:"investorProfile.investor.legalName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"john.doe@email.com"},model:{value:(_vm.investorProfile.investor.emailAddress),callback:function ($$v) {_vm.$set(_vm.investorProfile.investor, "emailAddress", $$v)},expression:"investorProfile.investor.emailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Short_name'),"label-for":"slugan"}},[_c('validation-provider',{attrs:{"name":"Short Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slugan","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Short_name')},model:{value:(_vm.investorProfile.investor.shortName),callback:function ($$v) {_vm.$set(_vm.investorProfile.investor, "shortName", $$v)},expression:"investorProfile.investor.shortName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Phone_Number'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"}},[_c('VuePhoneNumberInput',{attrs:{"color":"#ea5455","error-color":"#ea5455","default-country-code":_vm.investorProfile.investor.phone.code,"translations":_vm.$store.state.locale.locale == 'ar'
                      ? _vm.translationsArabic
                      : _vm.translations},on:{"update":_vm.onUpdate},model:{value:(_vm.investorProfile.investor.phone.phoneNumber),callback:function ($$v) {_vm.$set(_vm.investorProfile.investor.phone, "phoneNumber", $$v)},expression:"investorProfile.investor.phone.phoneNumber"}}),(!_vm.isValidNumber)?_c('small',{staticClass:"text-danger isValid"},[_vm._v(_vm._s(_vm.$t("common.phone_Invalid")))]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.SaveChanges"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }