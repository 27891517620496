<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.Facebook')"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  rules="url"
                >
                  <b-form-input
                    id="facebook"
                    v-model="companyProfile.facebook"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://facebook.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.Twitter')"
                label-for="twitter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Twitter"
                  rules="url"
                >
                  <b-form-input
                    id="twitter"
                    v-model="companyProfile.twitter"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://twitter.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.LinkedIn')"
                label-for="linked-in"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LinkedIn"
                  rules="url"
                >
                  <b-form-input
                    id="linked-in"
                    v-model="companyProfile.linkedIn"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://linkedin.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.Website')"
                label-for="Other"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="url"
                >
                  <b-form-input
                    id="Other"
                    v-model="companyProfile.otherLinks"
                    :state="errors.length > 0 ? false : null"
                    placeholder="https://other.com/abc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  props: {
    companyProfile: {},
  },
  data() {
    return {
      show: false,
      //   optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,

      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: "",
      phoneCode: "",
      selectedIndustry: [],
      createInvestorProfile: {
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        BusinessFactors: "",
        Designation: "",
        SelectedInterested: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        SelectedInvestorRole: "",
        Highlights: "",
        description: "",
        CompanyName: "",
        CompanyDescription: "",
        selectedDesiredCountry: [],
        selectedSaleType: "",
        SelectedCurrency: "",
        InvestmentMinSize: "",
        InvestmentMaxSize: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: [],
        pincode: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        ProfilePicture: [],
        CompanyLogo: [],
        ProofBusiness: [],
        CorporateProfile: [],
        InvestorRoleOption: [
          { value: "1", text: "Individual Investor / Buyer" },
          { value: "2", text: "Corporate Investor / Buyer" },
          {
            label: "Lender",
            options: [
              { value: "3", text: "Bank" },
              { value: "4", text: "Financial Institution" },
            ],
          },
          {
            label: "Financial Advisor",
            options: [
              { value: "3", text: "Accounting Firm" },
              { value: "4", text: "Business Broker" },
              { value: "5", text: "Financial Consultant" },
              { value: "6", text: "Investment Bank" },
              { value: "7", text: "Law Firm" },
              { value: "8", text: "M&A Advisor" },
              { value: "9", text: "Merchant Bank" },
              { value: "10", text: "Commercial Real Estate Broker" },
            ],
          },
          {
            label: "Fund",
            options: [
              { value: "11", text: "Venture Capital Firm" },
              { value: "12", text: "Private Equity Firm" },
              { value: "13", text: "Family Office" },
              { value: "14", text: "Hedge Fund" },
              { value: "15", text: "Search Fund" },
            ],
          },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Open Auction" },
          { value: "3", text: "Timed Auction" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      editCompany: "profile/editCompany",
    }),
    onUpdate(payload) {
      // console.log(payload);
      this.isValidNumber = payload.isValid;
      this.PhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const userData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            userData.append("userId", this.user._id);
            userData.append("userType", "company");

            if (this.companyProfile.facebook) {
              userData.append(
                "companyFacebookLink",
                this.companyProfile.facebook
              );
            }
            if (this.companyProfile.linkedIn) {
              userData.append(
                "companyLinkedinLink",
                this.companyProfile.linkedIn
              );
            }
            if (this.companyProfile.twitter) {
              userData.append(
                "companyTwitterLink",
                this.companyProfile.twitter
              );
            }
            if (this.companyProfile.otherLinks) {
              userData.append(
                "companyOtherLinks",
                this.companyProfile.otherLinks
              );
            }

            this.editCompany(userData)
              .then((response) => {
                // console.log(response.data.message);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                this.errorMessage = error.response.data.error.message;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    // resetForm() {
    //   this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    // },
  },
  //   setup() {
  //     const refInputEl = ref(null);
  //     const previewEl = ref(null);

  //     const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

  //     return {
  //       refInputEl,
  //       previewEl,
  //       inputImageRenderer,
  //     };
  //   },
};
</script>
