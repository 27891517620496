<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col md="6">
              <label for="example-datepicker"
                >{{ $t("CompanyForm.Date_of_establishment") }}
              </label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Establishment"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="companyProfile.company.establishDate"
                  :state="errors.length > 0 ? false : null"
                  class="mb-2"
                  :max="max"
                  :locale="$store.state.locale.locale"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="EBITDA"
                  >{{ $t("CompanyForm.EBITDA") }}
                  <!-- <i id="EBITDA-popover" class="fa-solid fa-circle-info" /> -->
                </label>
                <!-- <b-popover
                  target="EBITDA-popover"
                  title=""
                  placement="top"
                  triggers="hover focus"
                  :content="$t('companyPopover.EBITDA')"
                /> -->
                <validation-provider
                  #default="{ errors }"
                  name="EBITDA"
                  rules="required|between:-100,100"
                >
                  <b-form-input
                    id="EBITDA"
                    v-model="companyProfile.company.ebitda"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.EBITDA')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="Monthly-Sales"
                  >{{ $t("CompanyForm.average_monthly_sales") }}

                  <!-- <i
                    id="MonthlySales-popover"
                    class="fa-solid fa-circle-info"
                  /> -->
                </label>
                <!-- <b-popover
                  target="MonthlySales-popover"
                  title=""
                  placement="top"
                  triggers="hover focus"
                  :content="$t('companyPopover.MonthlySales')"
                /> -->
                <validation-provider
                  #default="{ errors }"
                  name="Monthly Sales"
                  rules="required|integer"
                >
                  <b-form-input
                    id="Monthly-Sales"
                    v-model="companyProfile.company.avgMonthlySales"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.average_monthly_sales')"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for="Annual-Sales"
                  >{{ $t("CompanyForm.reported_yearly_sales") }}
                  <!-- <i id="AnnualSales-popover" class="fa-solid fa-circle-info" /> -->
                </label>
                <!-- <b-popover
                  target="AnnualSales-popover"
                  title=""
                  placement="top"
                  triggers="hover focus"
                  :content="$t('companyPopover.AnnualSales')"
                /> -->
                <validation-provider
                  #default="{ errors }"
                  name="Annual Sales"
                  rules="required|integer"
                >
                  <b-form-input
                    id="Annual-Sales"
                    v-model="companyProfile.company.annualSales"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.reported_yearly_sales')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.company_belong_to')"
                  label-for="Industry"
                  :state="errors.length > 0 ? false : null"
                >
                  <div id="app">
                    <treeselect
                      v-model="companyProfile.company.industries"
                      :multiple="true"
                      :placeholder="$t('CompanyForm.company_belong_to')"
                      :options="industries"
                      @input="handeTreeSelect"
                      :normalizer="
                        $store.state.locale.locale == 'ar'
                          ? normalizerArabic
                          : normalizer
                      "
                    />
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.physical_assets')"
                label-for="Physical-Assets"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Physical Assets"
                  rules="required|integer"
                >
                  <b-form-input
                    id="Physical-Assets"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.physical_assets')"
                    v-model="companyProfile.company.physicalAssets"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.tangible_and_intangible')"
                label-for="Assets"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Assets"
                  rules="required"
                >
                  <b-form-input
                    id="Assets"
                    v-model="companyProfile.company.assets"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.tangible_and_intangible')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="categories"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.interested_in')"
                  label-for="categories"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="categories"
                    v-model="companyProfile.company.companyCategory"
                    :options="categories"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="
                companyProfile.company.companyCategory.value ===
                '62a1e60144fbaf36185a39da'
              "
              md="6"
            >
              <b-row>
                <b-col md="5" class="mt-NEG">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.Currency')"
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id=""
                        v-model="companyProfile.company.currency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="7">
                  <validation-provider
                    #default="{ errors }"
                    name="investment amount"
                    rules="required|integer"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.investment_amount_seeking')"
                      label-for="InvestmentPrice"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        id="InvestmentPrice"
                        v-model="companyProfile.company.price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="
                          $t('CompanyForm.investment_amount_seeking')
                        "
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="
                companyProfile.company.companyCategory.value ===
                '62a1e61144fbaf36185a39de'
              "
              md="6"
            >
              <b-row>
                <b-col md="5" class="mt-NEG">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.Currency')"
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id=""
                        v-model="companyProfile.company.currency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="7">
                  <validation-provider
                    #default="{ errors }"
                    name="loan Amount"
                    rules="required|integer"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.loan_amount_seeking')"
                      label-for="loanAmount"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        id="loanAmount"
                        v-model="companyProfile.company.price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('CompanyForm.loan_amount_seeking')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              v-if="
                companyProfile.company.companyCategory.value ===
                '62a1e64944fbaf36185a39e2'
              "
              md="6"
            >
              <b-row>
                <b-col md="5" class="mt-NEG">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.Currency')"
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id=""
                        v-model="companyProfile.company.currency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Selling or Leasing"
                    rules="required|integer"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.selling_leasing')"
                      label-for="selling"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        id="selling"
                        v-model="companyProfile.company.price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('CompanyForm.selling_leasing')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              md="6"
              v-if="
                companyProfile.company.companyCategory.value ===
                '62a1e5eb44fbaf36185a39d6'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="sale type"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.sell_your_company')"
                  label-for="saleType"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="saleType"
                    v-model="companyProfile.company.saleType"
                    :options="
                      $store.state.locale.locale == 'ar'
                        ? createCompanyProfile.SaleTypeOptionArabic
                        : createCompanyProfile.SaleTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-show="companyProfile.company.saleType.value === '1'"
              md="6"
            >
              <b-row>
                <b-col md="5" class="mt-NEG">
                  <validation-provider
                    #default="{ errors }"
                    name="Currency"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.Currency')"
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id=""
                        v-model="companyProfile.company.currency"
                        :options="currencies"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="7">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="required|integer"
                  >
                    <b-form-group
                      :label="$t('CompanyForm.Price')"
                      label-for="Price"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-input
                        id="Price"
                        v-model="companyProfile.company.price"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('CompanyForm.Price')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.License_Number')"
                label-for="License-Number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="License Number"
                  rules="required"
                >
                  <b-form-input
                    id="License-Number"
                    v-model="companyProfile.company.licenseNumber"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.License_Number')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="company type"
                rules="required"
              >
                <b-form-group
                  :label="$t('CompanyForm.legal_entity_type')"
                  label-for="Company-Type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="Company-Type"
                    v-model="companyProfile.company.companyType"
                    :options="
                      $store.state.locale.locale === 'ar'
                        ? createCompanyProfile.CompanyTypeOptionArabic
                        : createCompanyProfile.CompanyTypeOption
                    "
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.overview_your_business')"
                label-for="Highlights"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Highlights"
                  :rules="`required|max:${$maxHighlightsUser}`"
                >
                  <b-form-textarea
                    id="Highlights"
                    v-model="companyProfile.company.highlights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.overview_your_business_p')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('CompanyForm.description')"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-textarea
                    id="description"
                    v-model="companyProfile.company.description"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('CompanyForm.description')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="validationForm"
              >
                {{ $t("dashboard.SaveChanges") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormSelect,
  BPopover,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    BFormInvalidFeedback,
    BFormSelect,
    Treeselect,
    BPopover,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  //   props: {
  //     generalData: {
  //       type: Object,
  //       default: () => {},
  //     },
  //   },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    // 15th in two months
    const maxDate = new Date(today);
    return {
      show: false,
      max: maxDate,

      //   optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,

      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: "",
      phoneCode: "",
      selectedIndustry: [],
      createCompanyProfile: {
        selectedContry: "",
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        Establishment: "",
        EBITDA: "",
        MonthlySales: "",
        AnnualSales: "",
        PhysicalAssets: "",
        Assets: "",
        LicenseNumber: "",
        SelectedCompanyType: "",
        SelectedCategories: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        city: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedIndustry: "",
        Highlights: "",
        description: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: "",
        otherInfo: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        companyPhoneCode: "",
        ProfilePicture: null,
        CompanyDocuments: null,
        ProofBusiness: null,
        CompanyPhotos: null,
        CompanyTypeOption: [
          { value: "1", text: "Sole Proprietorship/Sole Trader" },
          { value: "2", text: "General Partnership" },
          { value: "3", text: "Limited Liability Partnership (LLP)" },
          { value: "4", text: "Limited Liability Company (LLC)" },
          { value: "5", text: "Private Limited Company" },
          { value: "6", text: "Public Limited Company" },
          { value: "7", text: "S Corporation" },
          { value: "8", text: "C Corporation" },
          { value: "9", text: "Other" },
        ],
        CompanyTypeOptionArabic: [
          { value: "1", text: "مؤسسة فردية / تاجر وحيد" },
          { value: "2", text: "شراكة عامة" },
          { value: "3", text: "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)" },
          { value: "4", text: "شركة ذات مسؤولية محدودة(ش.ذ.م.م)" },
          { value: "5", text: "شركة خاصة محدودة" },
          { value: "6", text: "شركه عالميه محدوده" },
          { value: "7", text: "S Corporation" },
          { value: "8", text: "C Corporation" },
          { value: "9", text: "غير ذلك" },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],
      },
      companyProfileFormated: {
        saleType: null,
      },

      companyPopover: {
        EBITDA:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        MonthlySales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        AnnualSales:
          "Investors/Buyers evaluate your business proposal based on this information. It is best to communicate this information upfront instead of wasting your time and theirs.",
        CompanyImage:
          "Kindly upload your facility / office pictures as profiles with photos get higher rating and visibility. You can upload jpg, bmp, png, tiff, gif files which are less than 3MB in size.",
        CompanyDocuments:
          "Profiles with documents get higher rating and visibility. Documents uploaded are accessible only to members introduced to you. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif, mp4 files which are less than 3MB in size.",
        CompanyProof:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
        companyPhotos:
          "You can attach documents such as tax certificate, incorporation certificate, govt registration certificate or evidence of revenue such as bank statement, screenshot of sales, etc. Uploading this document will help our review team to verify and approve your profile faster. You can upload word, excel, powerpoint, pdf, jpg, bmp, png, gif files which are less than 3MB in size.",
      },
    };
  },
  props: {
    companyProfile: {},
  },

  computed: {
    ...mapGetters({
      categories: "profile/getCompanyCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      //   createInvestor: "profile/createInvestor",
      fetchCountries: "country/retreiveCountries",
      editCompany: "profile/editCompany",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.companyProfile.company.industries.length = 6;
      }
    },
    // companyFormated() {
    //   if (this.companyProfile.company.saleType === "1") {
    //     this.companyProfile.company.saleType = {
    //       value: this.companyProfile.company.saleType,
    //       text: "My Own Price",
    //     };
    //   }
    // },
    onUpdate(payload) {
      // console.log(payload);
      this.isValidNumber = payload.isValid;
      this.PhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.show = true;
            const userData = new FormData();
            // eslint-disable-next-line no-underscore-dangle
            userData.append("userId", this.user._id);
            userData.append("userType", "company");
            userData.append(
              "establishDate",
              this.companyProfile.company.establishDate
            );
            userData.append("ebitda", this.companyProfile.company.ebitda);
            userData.append(
              "avgMonthlySales",
              this.companyProfile.company.avgMonthlySales
            );
            userData.append(
              "annualSales",
              this.companyProfile.company.annualSales
            );
            userData.append("assets", this.companyProfile.company.assets);
            userData.append(
              "physicalAssets",
              this.companyProfile.company.physicalAssets
            );
            userData.append(
              "companyCategory",
              this.companyProfile.company.companyCategory.value
            );
            if (this.companyProfile.company.price) {
              userData.append("price", this.companyProfile.company.price);
            }
            if (this.companyProfile.company.currency) {
              userData.append(
                "currency",
                this.companyProfile.company.currency.value
              );
            }

            userData.append(
              "licenseNumber",
              this.companyProfile.company.licenseNumber
            );
            userData.append(
              "companyType",
              this.companyProfile.company.companyType.value
            );
            userData.append(
              "saleType",
              this.companyProfile.company.saleType.value
            );
            userData.append(
              "highlights",
              this.companyProfile.company.highlights
            );
            userData.append(
              "description",
              this.companyProfile.company.description
            );
            userData.append(
              "industries",
              JSON.stringify(this.companyProfile.company.industries)
            );

            this.editCompany(userData)
              .then((response) => {
                // console.log(response.data.message);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("messages.Modified_successfully"),
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                this.errorMessage = error.response.data.error.message;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    pluck(array, key) {
      return array.map((o) => o[key]);
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
  },
};
</script>
<style scoped>
.mt-NEG {
  margin-top: -3px;
}
</style>
